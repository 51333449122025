import { gql } from '@apollo/client';

export const finishVendorCompanyDetailsMutation = gql`
  mutation finishVendorCompanyDetails(
    $record: FinishVendorCompanyDetailsRecord!
  ) {
    finishVendorCompanyDetails(record: $record) {
      uid
      mediaUrls
      about
      name
    }
  }
`;
