import { gql } from '@apollo/client';

export const meQuery = gql`
  query me {
    me {
      uid
      role
      vendor {
        uid
        name
        about
        companyDetailsCompleted
        categoriesCompleted
        vendorMediaInfoCompleted
      }
      host {
        uid
        payoutDetails
        profileCompleted
      }
    }
  }
`;
