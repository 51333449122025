import styled from 'styled-components';

export const BannerImage = styled.div`
  background-image: url(${props => props.imgUrlMobile});
  width: 100%;
  height: 77.1rem;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: relative;
  ${({ theme }) => theme.laptop`
    background-image: url(${props => props.imgUrl});
    height: 89.4rem;
    justify-content: center;
  `}
`;

export const Gradient = styled.div`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(26, 29, 35, 0.75) 0%,
      rgba(26, 29, 35, 0.5) 66.28%,
      #1a1d23 100%
    );
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
`;

export const BannerHeading = styled.p`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  color: ${({ theme }) => theme.colors.white};
  font-size: 3.6rem;
  text-align: center;
  z-index: 1;
  ${({ theme }) => theme.laptop`
    font-size: ${props => (props.host ? '7.2rem' : '9.6rem')};
    width: 83.1rem;
    text-align: center;
  `};
`;

export const BannerText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.75;
  font-size: 1.4rem;
  width: 30rem;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  text-align: center;
  padding-top: 1rem;
  ${({ theme }) => theme.laptop`
    width: 47.6rem;
    font-size: 1.6rem;
  `}
`;

export const SmallCircleWrapper = styled.div`
  display: flex;
  padding-bottom: 3rem;
  padding-top: 2rem;
  z-index: 2;
  ${({ theme }) => theme.laptop`
    padding-top: 2rem;
  `}
`;

export const SmallCircle = styled.div`
  width: 1rem;
  height: 1rem;
  border: 0.2rem solid ${({ theme }) => theme.colors.whiteWithOpacity(0.5)};
  border-radius: 50%;
  margin-left: 1rem;
  :first-child {
    margin-left: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    border: none;
  }
`;

export const ButtonWrapper = styled.div`
  width: 17rem;
  height: 4.8rem;
  margin: 3rem 0 2.4rem;
  z-index: 5;
  ${({ theme }) => theme.laptop`
     width: 40rem;
   `}
`;

export const InputWrapper = styled.div`
  display: none;
  position: relative;
  ${({ theme }) => theme.laptop`
    width: 45rem;
    height: 4.8rem;
    display: unset;
  `}
`;

export const InputIcon = styled.img`
  position: absolute;
  z-index: 12;
  right: 2.2rem;
  top: 1.4rem;
`;
