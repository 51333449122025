import media from 'styles/media';

const theme = {
  colors: {
    whiteWithOpacity: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    blackWithOpacity: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    greenWithOpacity: (opacity = 1) => `rgba(67, 197, 136, ${opacity})`,
    primaryWithOpacity: (opacity = 1) => `rgba(255,165,87,${opacity})`,
    greyWithOpacity: (opacity = 1) => `rgba(52,55,61,${opacity})`,
    white: '#ffffff',
    primary: '#FFA557',
    errorRed: '#ec506a',
    grey: '#34373D',
    successGreen: '#43C588',
    darkGrey: '#1A1D23',
    mediumGrey: '#2C2E34',
    lightGrey: '#292D32',
    green: '#43C588',
    black: '#000000',
    lightBlack: '#14161A',
    greyLight: '#9A9B9E',
    greyDropDown: '#42474E',
    orange: '#ffa557',
    greyLighter: '#8C8E91',
  },
  fonts: {
    primaryBold: 'montserrat-b',
    primaryBoldItalic: 'montserrat-b-i',
    primaryItalic: 'montserrat-i',
    primaryLight: 'montserrat-l',
    primaryLightItalic: 'montserrat-l-i',
    primaryMedium: 'montserrat-m',
    primaryMediumItalic: 'montserrat-m-i',
    primaryRegular: 'montserrat-r',
    primarySemiBold: 'montserrat-s-b',
    primarySemiBoldItalic: 'montserrat-s-b-i',
  },
  ...media,
};

export default theme;
