import styled from 'styled-components';

export const ContentWrapper = styled.div`
  width: 48rem;
  padding-bottom: 4rem;
`;

export const ModalHeading = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 3.6rem;
  padding-top: 3rem;
  margin-right: 19.3rem;
`;

export const Form = styled.form`
  width: 100%;
  padding: 0 4rem;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-top: ${({ isFirst }) => (isFirst ? '3rem' : '2rem')};
  position: relative;
`;

export const InputHeading = styled.p`
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.7;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  padding-bottom: 1rem;
`;

export const ButtonWrapper = styled.div`
  height: 4.8rem;
  padding: 0 4rem;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: ${({ isLast }) => (isLast ? '5rem' : '0')};
`;

export const Circle = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 2.5rem;
  right: 2.9rem;
`;

export const Border = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.colors.grey};
  margin-top: 8rem;
  :first-child {
    margin-top: 0rem;
  }
  ${({ theme }) => theme.laptop`
   border: unset;
 `}
`;

export const Footer = styled.div`
  position: fixed;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.primary};
  bottom: 5.6rem;
`;

export const InputCircle = styled.button`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.colors.grey};
  position: absolute;
  right: 1.5rem;
  bottom: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  z-index: 12;
`;

export const InputActionIcon = styled.img`
  width: 0.5rem;
`;
