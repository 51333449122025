import styled from 'styled-components';

export const OfferContainer = styled.div`
  width: 100%;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  background-color: ${({ theme }) => theme.colors.darkGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RectangleInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.6rem;
  width: 100%;
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 4.8rem;
  margin-top: 2rem;
  position: relative;
  margin-right: 0.5rem;
  ${({ theme }) => theme.laptop`
    display: none;
  `}
`;

export const SmallRectangle = styled.div`
  background-color: ${({ theme }) => theme.colors.mediumGrey};
  width: 3.5rem;
  height: 0.5rem;
  border-radius: 1.2rem;
  margin-top: 2rem;
  ${({ theme }) => theme.laptop`
    display: none;
  `}
`;

export const OfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 140rem;
  width: 100%;
  padding: 0 2.7rem;
  ${({ theme }) => theme.laptop`
      align-items: center;
    `}
`;

export const OfferHeading = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.8rem;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  padding-top: 3rem;
  ${({ theme }) => theme.laptop`
    display: none;
  `}
`;

export const OfferCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const OfferCardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  width: 100%;
  overflow-x: auto;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const CategoriesContainer = styled.div`
  display: flex;
  max-width: 80rem;
  overflow: scroll;
  ${({ theme }) => theme.laptop`
    overflow: unset;
   `}
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.laptop`
    flex-wrap: wrap;
    `}
`;

export const CategoryWrapper = styled.div`
  margin-left: ${({ noMargin }) => (noMargin ? '0' : '1.6rem')};
  margin-top: 2.5rem;
  :first-child {
    margin-left: 0;
  }
  ${({ theme }) => theme.laptop`
   :first-child {
     margin-left: 1.6rem;
   }
   `}
`;

export const ButtonWrapper = styled.div`
  margin: 3rem 0 14.9rem;
  padding: 0 2.6rem;
  width: 100%;
  ${({ theme }) => theme.laptop`
     width: 29.5rem;
    `}
`;

export const LongButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 1.4rem;
  width: 100%;
  height: 4.8rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.grey};
  border: none;
  cursor: pointer;
`;

export const CircleContainer = styled.div`
  display: none;
  ${({ theme }) => theme.laptop`
    display: flex;
    margin-left: 1.8rem;
    margin-top: 3rem;
  `}
`;

export const Circle = styled.button`
  border-radius: 50%;
  background: transparent;
  border: none;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ isRight }) => (isRight ? 'rotate(180deg)' : '0')};
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
