import styled from 'styled-components';

export const ButtonContainer = styled.button`
  width: 4.8rem;
  height: 4.8rem;
  border: none;
  border-radius: 50%;
  background-color: ${({ theme, isPrimary }) =>
    isPrimary ? theme.colors.primary : theme.colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, hasHover }) =>
      hasHover ? theme.colors.primary : 'none'};
  }
`;
