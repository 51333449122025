import styled from 'styled-components';

export const OfferContainer = styled.div`
  width: 25.3rem;
  height: 32.2rem;
  padding-top: 2rem;
  margin-left: 1.6rem;
  :first-child {
    margin-left: 0;
  }
`;

export const OfferBackgroundImg = styled.div`
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  border-radius: 2.4rem;
  margin-left: 2rem;
  :first-child {
    margin-left: 0;
  }
`;

export const Rectangle = styled.div`
  width: 23.6rem;
  height: 8.5rem;
  background-color: rgba(50, 52, 58, 0.75);
  border-radius: 0.8rem;
  margin: 0 0.8rem 0.8rem;
  display: flex;
  justify-content: space-between;
  padding: 1.3rem;
`;

export const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: 1.2rem;
  font-weight: 800;
`;

export const CurrentPrice = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: 1.4rem;
`;

export const OldPrice = styled.span`
  color: ${({ theme }) => theme.colors.greyLighter};
  font-size: 1.4rem;
  margin-left: 1rem;
  text-decoration: line-through;
`;

export const ToursText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.75;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
`;

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StarRateWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const StarImg = styled.img`
  padding-right: 0.4rem;
`;

export const Rate = styled.span`
  color: ${({ theme }) => theme.colors.white};
`;

export const LocationDistanceWrapper = styled.div`
  display: flex;
`;

export const LocationIcon = styled.img`
  padding-right: 0.3rem;
`;

export const Distance = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
`;
