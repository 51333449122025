import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, path, ...props }) => {
  return (
    <Route path={path} {...props}>
      <Component />
    </Route>
  );
};

export default PublicRoute;
