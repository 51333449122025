import { gql } from '@apollo/client';

export const registerHostMutation = gql`
  mutation register($record: CreateVendorOrHostRecord!) {
    registerHost(record: $record) {
      host {
        uid
      }
      accessToken
    }
  }
`;
