const SCREEN_NAMES = {
  HOME: '/',
  ABOUT: '/about-us',
  HOST: '/host',
  HOST_ONBOARDING: '/host/:step',
  ONBOARDING_LOGIN: '/log-in',
  ONBOARDING_REGISTER: '/register',
  ONBOARDING_COMPANY_DETAILS: '/company-details',
  HOST_ONBOARDING_LOGIN: '/host/log-in',
  HOST_ONBOARDING_REGISTER: '/host/register',
  HOST_ONBOARDING_COMPANY_DETAILS: '/host/company-details',
  HOST_ONBOARDING_WELCOME: '/host/welcome',
  HOST_ONBOARDING_FORGOT_PASSWORD: '/host/forgot-password',
  HOST_ONBOARDING_FORGOT_RESET_PASSWORD: '/host/reset-password',
  VENDOR: '/vendor',
  VENDOR_ONBOARDING: '/vendor/:step',
  VENDOR_ONBOARDING_LOGIN: '/vendor/log-in',
  VENDOR_ONBOARDING_REGISTER: '/vendor/register',
  VENDOR_ONBOARDING_COMPANY_DETAILS: '/vendor/company-details',
  VENDOR_ONBOARDING_CHOOSE_CATEGORIES: '/vendor/choose-categories',
  VENDOR_ONBOARDING_FINISH_COMPANY_DETAILS: '/vendor/finish-company-details',
  VENDOR_ONBOARDING_WELCOME: '/vendor/welcome',
  HOST_PLATFORM: '/host/platform',
  VENDOR_PLATFORM: '/vendor/platform',
  VENDOR_PLATFORM_DETAILS: '/vendor/platform/:item',
  VENDOR_PLATFORM_PROFILE: '/vendor/platform/profile',
  VENDOR_PLATFORM_NEW_OFFER: '/vendor/platform/new-offer',
  VENDOR_PLATFORM_REVIEW: '/vendor/platform/review',
  VENDOR_PLATFORM_ORDERS: '/vendor/platform/orders',
  VENDOR_PLATFORM_OFFERS: '/vendor/platform/offers',
  NOTIFICATIONS: '/notifications',
};

export default SCREEN_NAMES;
