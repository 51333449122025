import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.darkGrey};
`;

export const InputMainContainer = styled.div`
  width: 33rem;
`;

export const CategoryContainer = styled.div`
  display: flex;
  overflow-x: auto;
  ${({ theme }) => theme.laptop`
    flex-direction: column;
    align-items: center;
    `}
`;

export const DownloadContainer = styled.div`
  width: 42.5rem;
`;
