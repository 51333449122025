import { gql } from '@apollo/client';

export const updateVendorCompanyDetailsMutation = gql`
  mutation updateVendorCompanyDetails(
    $record: UpdateVendorCompanyDetailsRecord!
  ) {
    updateVendorCompanyDetails(record: $record) {
      uid
      name
      about
      mediaUrls
      categories {
        uid
      }
    }
  }
`;
