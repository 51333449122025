import { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  HeaderWrapper,
  MobileHeaderRectangle,
  LogoIconWrapper,
  IconWrapper,
  MenuIcon,
  Nav,
  NavList,
  NavListItem,
} from './header.style';
import Logo from 'assets/icons/Logo-image.svg';
import GlobalIcon from 'assets/icons/global.svg';
// import profileIcon from 'assets/icons/profile-icon.svg';
import MenuIconImg from 'assets/icons/menu.svg';
import SCREEN_NAMES from 'constants/screen-names';

const Header = ({ hasBg }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showMenu, setShowMenu] = useState(false);

  const menuHandleClick = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const scrollToFooter = () => {
    window.scrollTo({
      top: 5000,
      behavior: 'smooth',
    });
  };

  return (
    <HeaderWrapper hasBackgroundColor={hasBg}>
      <MobileHeaderRectangle />
      <LogoIconWrapper>
        <IconWrapper>
          <Link to="/">
            <img src={Logo} alt="SpiceApp Logo" />
          </Link>
        </IconWrapper>
        <MenuIcon src={MenuIconImg} alt="Menu Icon" onClick={menuHandleClick} />
      </LogoIconWrapper>
      <Nav showMenu={showMenu}>
        <NavList>
          <NavListItem>
            <Link to={SCREEN_NAMES.HOME}>Home</Link>
          </NavListItem>
          <NavListItem>
            <Link to={SCREEN_NAMES.ABOUT}>About us</Link>
          </NavListItem>
          <NavListItem onClick={scrollToFooter} hasBorder>
            Contact us
          </NavListItem>
          <NavListItem>
            <Link to={SCREEN_NAMES.HOST}>I'm a Host</Link>
          </NavListItem>
          <NavListItem>
            <Link to={SCREEN_NAMES.VENDOR}>I'm a Vendor</Link>
          </NavListItem>
          <NavListItem globalIcon>
            <IconWrapper>
              <img src={GlobalIcon} alt="Global Icon" />
            </IconWrapper>
          </NavListItem>
        </NavList>
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;
