import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Home from 'screens/home/Home';
import AboutUs from 'screens/about-us/AboutUs';
// import Host from 'screens/host/Host';
// import Vendor from 'screens/vendor/Vendor';
// import HostPlatform from 'screens/host-platform/HostPlatform';
// import VendorPlatform from 'screens/vendor-platform/VendorPlatform';
// import Notifications from 'screens/notifications/Notifications';
import SCREEN_NAMES from '../constants/screen-names';
import PublicRoute from './PublicRoute';
// import PrivateRoute from './PrivateRoute';

const Routing = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path={SCREEN_NAMES.HOME} component={Home} />
        <PublicRoute exact path={SCREEN_NAMES.ABOUT} component={AboutUs} />
        {/* <PublicRoute
          exact
          path={SCREEN_NAMES.NOTIFICATIONS}
          component={Notifications}
        />
        <PrivateRoute
          exact
          path={SCREEN_NAMES.HOST_PLATFORM}
          component={HostPlatform}
        />
        <PrivateRoute
          exact
          path={[
            SCREEN_NAMES.VENDOR_PLATFORM,
            SCREEN_NAMES.VENDOR_PLATFORM_DETAILS,
          ]}
          component={VendorPlatform}
        />
        <PublicRoute
          exact
          path={[SCREEN_NAMES.HOST_ONBOARDING, SCREEN_NAMES.HOST]}
          component={Host}
        />
        <PublicRoute
          exact
          path={[SCREEN_NAMES.VENDOR_ONBOARDING, SCREEN_NAMES.VENDOR]}
          component={Vendor}
        /> */}
      </Switch>
    </Router>
  );
};

export default Routing;
