import { gql } from '@apollo/client';

export const getOfferByIdQuery = gql`
  query getOfferById($record: OfferIdRecord!) {
    getOfferById(record: $record) {
      uid
      description
      name
      mediaUrls
      totalPrice
      discountedPrice
      status
      publishedAt
      vendor {
        uid
        name
        categories {
          uid
          name
        }
        user {
          uid
          fullName
        }
      }
    }
  }
`;
