import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import SCREEN_NAMES from 'constants/screen-names';
import useAuth from 'services/auth/useAuth';
import {
  BannerImage,
  BannerHeading,
  Gradient,
  InputWrapper,
  BannerText,
  SmallCircleWrapper,
  SmallCircle,
} from './banner.style';
import Input from 'components/common/input/Input';
import CustomButton from 'components/common/button/Button';
import { ButtonWrapper } from './banner.style';
import SearchIcon from 'assets/icons/search-normal.svg';

const Banner = ({
  heading,
  imgUrl,
  imgUrlMobile,
  text,
  hasInput,
  hasButton,
}) => {
  const { me } = useAuth();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      searchPlaces: '',
    },
  });

  const openModal = () => {
    if (me) {
      if (me.role === 'HOST') {
        history.push(SCREEN_NAMES.HOST_PLATFORM);
      } else if (me.role === 'VENDOR') {
        history.push(SCREEN_NAMES.VENDOR_PLATFORM);
      }
    } else {
      history.replace(
        history.location.pathname + SCREEN_NAMES.ONBOARDING_LOGIN
      );
    }
  };

  return (
    <BannerImage imgUrl={imgUrl} imgUrlMobile={imgUrlMobile}>
      <Gradient />
      <BannerHeading host>{heading}</BannerHeading>
      <BannerText>{text}</BannerText>
      {hasButton && (
        <ButtonWrapper host>
          <CustomButton buttonType="primary" onClick={openModal}>
            Let's Start
          </CustomButton>
        </ButtonWrapper>
      )}
      <SmallCircleWrapper>
        <SmallCircle />
        <SmallCircle />
        <SmallCircle />
      </SmallCircleWrapper>
      {hasInput && (
        <InputWrapper>
          <Input
            value={formik.values.searchPlaces}
            placeholder="Search places"
            host
            name="searchPlaces"
            onChange={formik.handleChange}
            icon={SearchIcon}
          />
        </InputWrapper>
      )}
    </BannerImage>
  );
};

export default Banner;
