import { gql } from '@apollo/client';

export const chooseVendorCategoriesMutation = gql`
  mutation chooseVendorCategories($record: ChooseVendorCategoriesRecord!) {
    chooseVendorCategories(record: $record) {
      uid
      categories {
        uid
        name
      }
    }
  }
`;
