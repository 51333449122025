import { gql } from '@apollo/client';

export const getCategoriesQuery = gql`
  query getCategories {
    getCategories {
      uid
      label
    }
  }
`;
