import styled from 'styled-components';
import theme from 'styles/theme';

export const CategoryWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 1.5rem 2.7rem;
  width: auto;
  border: ${({ hasBorder, theme }) =>
    hasBorder ? `0.2rem solid ${theme.colors.grey}` : 'none'};
  border-radius: 1rem;
  background: ${({ hasBackground }) =>
    hasBackground ? theme.colors.grey : 'transparent'};
  cursor: pointer;
  :first-child {
    margin-left: 0;
  }
`;

export const CategoryImg = styled.img`
  width: 2.8rem;
  height: 2.8rem;
`;

export const CategoryText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: 1.4rem;
  padding-left: 1rem;
  min-width: 7.9rem;
`;
