import { useHistory } from 'react-router-dom';

import { MainContainer } from 'screens/home/home.styles';
import Header from 'components/header/Header';
import Banner from 'components/common/banner/Banner';
import Footer from 'components/footer/Footer';
import OfferSection from 'screens/home/offer-section/OfferSection';
import BecomeSection from './become-section/BecomeSection';
import BannerImgLarge from 'assets/imgs/banner-laptop.svg';
import BannerImg from 'assets/imgs/landing-bg-mobile.svg';
import Border from 'screens/home/border/Border';
import DownloadSection from './download-section/DownloadSection';
import HostBackgroundImg from 'assets/imgs/Kazb.jpg';
import HostBackgroundImgLarge from 'assets/imgs/Kazb.jpg';
import VendorBackgroundImg from 'assets/imgs/mountain.jpg';
import VendorBackgroundImgLarge from 'assets/imgs/mountain.jpg';
import SCREEN_NAMES from 'constants/screen-names';

const Home = () => {
  const history = useHistory();

  const redirectToHostPage = () => {
    history.push(SCREEN_NAMES.HOST);
  };

  const redirectToVendorPage = () => {
    history.push(SCREEN_NAMES.VENDOR);
  };

  return (
    <MainContainer>
      <Header hasBg />
      <Banner
        heading="Spice Up Your stay here"
        imgUrl={BannerImgLarge}
        imgUrlMobile={BannerImg}
        hasInput
      />
      <OfferSection />
      <Border />
      <DownloadSection />
      <Border />
      <BecomeSection
        heading="Become a Vendor"
        text=" Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat"
        bg={HostBackgroundImg}
        bgLaptop={HostBackgroundImgLarge}
        onClick={redirectToVendorPage}
      />
      <Border />
      <BecomeSection
        heading="Become a Host"
        text=" Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat"
        bg={VendorBackgroundImg}
        bgLaptop={VendorBackgroundImgLarge}
        onClick={redirectToHostPage}
      />
      <Footer />
    </MainContainer>
  );
};

export default Home;
