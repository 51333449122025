import { AuthProvider } from 'services/auth/authContext';
import useAuth from 'services/auth/useAuth';
import GlobalStyles from 'styles/global-styles';
import Routing from './navigation/Routing';

const App = () => {
  const auth = useAuth();
  return (
    <AuthProvider value={auth}>
      <GlobalStyles />
      <Routing />
    </AuthProvider>
  );
};

export default App;
