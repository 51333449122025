import { gql } from '@apollo/client';

export const updateHostMutation = gql`
  mutation updateHost($record: UpdateHostRecord!) {
    updateHost(record: $record) {
      uid
      user {
        uid
      }
      payoutDetails
      addresses {
        uid
      }
    }
  }
`;
