import styled from 'styled-components';

export const ButtonContainer = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 2.7rem;
  background-color: ${({ theme, buttonType }) => theme.colors[buttonType]};
  border: none;
  color: ${({ theme, textColor }) => theme.colors[textColor]};
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 1.4rem;
  cursor: pointer;
  z-index: 5;
    }
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 0 1.6rem;
`;

export const TransparentButtonWrapper = styled.div`
  opacity: 0;
`;

export const ContentWrapper = styled.div`
  flex: 1;
`;
