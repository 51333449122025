import styled from 'styled-components';

export const InputContainer = styled.div`
  width: auto;
  position: relative;
`;

export const InputWrapper = styled.input`
  background: ${({ theme, isTransparent }) =>
    isTransparent ? 'transparent' : theme.colors.mediumGrey};
  width: 100%;
  height: 4.8rem;
  border-radius: 1.2rem;
  z-index: 3;
  border: 0.1rem solid
    ${props =>
      props.hasError ? props.theme.colors.errorRed : props.theme.colors.grey};
  font-size: 1.6rem;
  padding-left: 1.6rem;
  ::placeholder {
   color: ${props =>
     props.hasError
       ? props.theme.colors.errorRed
       : 0.7 % props.theme.colors.white}
  }
  color: ${props => props.theme.colors.white};
  position: relative;
  &:hover {
    border: 0.1rem solid ${props => props.theme.colors.primary};
  }
 }
`;

export const ErrorText = styled.p`
  color: ${props => props.theme.colors.errorRed};
  font-size: 1.2rem;
  font-family: ${props => props.theme.fonts.primaryRegular};
  position: absolute;
  top: 5.5rem;
`;

export const Circle = styled.button`
  width: 1.9rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1.5rem;
  bottom: 2.3rem;
  z-index: 4;
`;

export const Icon = styled.img`
  width: 0.5rem;
  height: 0.5rem;
`;

export const EyeIcon = styled.img`
  position: absolute;
  right: 2rem;
  top: 1.2rem;
  z-index: 10;
`;
