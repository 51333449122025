import { css } from 'styled-components';

const screenSizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 428,
  sTab: 640,
  midTablet: 726,
  tablet: 768,
  lTablet: 940,
  laptop: 1024,
  laptopM: 1224,
  laptopL: 1440,
  desktop: 2560,
};

export default Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = (...args) => {
    if (args.length > 0) {
      const [firstArg, ...restOfArgs] = args;

      return css`
        @media (min-width: ${screenSizes[label]}px) {
          ${css(firstArg, ...restOfArgs)}
        }
      `;
    }
  };

  return acc;
}, {});
