import { useCallback, useMemo, useState } from 'react';

import {
  InputContainer,
  InputWrapper,
  ErrorText,
  EyeIcon,
} from './input.style';
import eyeIcon from 'assets/icons/show-password-icon.svg';
import crossedEyeIcon from 'assets/icons/crossed-eye.svg';
import { InputIcon } from 'components/common/banner/banner.style';
import {
  InputCircle,
  InputActionIcon,
} from 'screens/host-platform/host-platform-profile/profileModal.style';

const Input = ({
  onChange,
  value,
  error,
  placeholder,
  type = 'text',
  name,
  isTransparent,
  icon,
  onBlur = () => {},
  actionIcon,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const clearInput = useCallback(() => {
    onChange('');
  }, [onChange]);

  const togglePassword = useCallback(() => {
    setShowPassword(passwordShown => !passwordShown);
  }, []);

  const inputType = useMemo(() => {
    if (type === 'password' && showPassword) {
      return 'text';
    }
    return type;
  }, [type, showPassword]);

  return (
    <InputContainer>
      <InputWrapper
        name={name}
        placeholder={placeholder}
        hasError={error}
        value={value}
        onChange={onChange}
        type={inputType}
        isTransparent={isTransparent}
        onBlur={onBlur}
      />
      {type === 'password' && (
        <EyeIcon
          src={showPassword ? crossedEyeIcon : eyeIcon}
          onClick={togglePassword}
        />
      )}
      {!!actionIcon && (
        <InputCircle onClick={clearInput} type="text">
          <InputActionIcon src={actionIcon} />
        </InputCircle>
      )}
      {!!icon && <InputIcon src={icon} />}
      <ErrorText>{error}</ErrorText>
    </InputContainer>
  );
};

export default Input;
