import { useState } from 'react';
import { useQuery } from '@apollo/client';

import {
  OfferContainer,
  RectangleInputWrapper,
  SmallRectangle,
  CircleContainer,
  Circle,
  OfferCardWrapper,
  OfferCardContainer,
  Container,
  CategoriesContainer,
  CategoriesWrapper,
  CategoryWrapper,
  OfferWrapper,
  OfferHeading,
  ButtonWrapper,
  LongButton,
  InputWrapper,
} from './offerSection.style';

import { getCategoriesQuery } from 'gql';

import Category from '../../../components/common/category/Category';
import OfferCard from 'components/common/offerCard/OfferCard';
import Input from 'components/common/input/Input';
// import Sparkle from 'assets/icons/sparkle.svg';
import OfferImg from 'assets/imgs/offer-img.svg';
// import Star from 'assets/icons/shiny-star.svg';
import Entertainment from 'assets/icons/entertainment.svg';
// import Bus from 'assets/icons/tours.svg';
// import Car from 'assets/icons/car-rental.svg';
// import RestBar from 'assets/icons/restaurants.svg';
// import Culture from 'assets/icons/culture.svg';
// import Local from 'assets/icons/map.svg';
// import Shopping from 'assets/icons/shopping.svg';
import Arrow from 'assets/icons/left-arrow.svg';
// import { getActiveOffersQuery } from 'gql';

const OfferSection = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const { data } = useQuery(getCategoriesQuery);

  const handleChooseCategory = uid => {
    setSelectedCategories(categories => {
      if (categories.includes(uid)) {
        return categories.filter(id => id !== uid);
      } else {
        return [...categories, uid];
      }
    });
  };

  // const { data } = useQuery(getActiveOffersQuery, {
  //   variables: {
  //     record: {
  //       categoryIds: [],
  //     },
  //   },
  // });

  const toggleCard = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <OfferContainer>
      <RectangleInputWrapper>
        <SmallRectangle />
        <InputWrapper>
          <Input
            value={searchValue}
            placeholder="Search places"
            host
            name="search"
            onChange={setSearchValue}
            searchIcon
          />
        </InputWrapper>
      </RectangleInputWrapper>
      <OfferWrapper>
        <OfferHeading>Best of Offer</OfferHeading>
        <Container>
          <CategoriesContainer>
            <CategoriesWrapper>
              {data?.getCategories?.map(({ uid, label }) => {
                return (
                  <CategoryWrapper key={uid}>
                    <Category
                      hasBorder
                      src={Entertainment}
                      text={label}
                      handleClick={() => handleChooseCategory(uid)}
                      hasBackground={selectedCategories.includes(uid)}
                    />
                  </CategoryWrapper>
                );
              })}
            </CategoriesWrapper>
          </CategoriesContainer>
          <CircleContainer hasMargin onClick={toggleCard}>
            <Circle>
              <img src={Arrow} alt="left arrow icon" />
            </Circle>
            <Circle isRight>
              <img src={Arrow} alt="right arrow icon" />
            </Circle>
          </CircleContainer>
        </Container>
        <OfferCardContainer>
          <OfferCardWrapper isExpanded={isExpanded}>
            {data?.getActiveOffers?.map(offer => {
              return (
                <OfferCard
                  {...offer}
                  bg={OfferImg}
                  rate="4.7"
                  distance="0.6 Km"
                />
              );
            })}
          </OfferCardWrapper>
        </OfferCardContainer>
      </OfferWrapper>
      <ButtonWrapper>
        <LongButton>See More</LongButton>
      </ButtonWrapper>
    </OfferContainer>
  );
};

export default OfferSection;
