import {
  OfferContainer,
  OfferBackgroundImg,
  Rectangle,
  LeftSideWrapper,
  Title,
  ToursText,
  CurrentPrice,
  OldPrice,
  RightSideWrapper,
  StarRateWrapper,
  StarImg,
  Rate,
  LocationDistanceWrapper,
  LocationIcon,
  Distance,
} from './offerCard.style';
import StarIconImg from 'assets/icons/star.svg';
import LocationIconImg from 'assets/icons/location.svg';

const OfferCard = ({
  name,
  discountedPrice,
  totalPrice,
  distance,
  rate,
  bg,
}) => {
  return (
    <OfferContainer>
      <OfferBackgroundImg bg={bg}>
        <Rectangle>
          <LeftSideWrapper>
            <Title title={name}>{name}</Title>
            <div>
              <CurrentPrice>{discountedPrice}</CurrentPrice>
              <OldPrice>{totalPrice}</OldPrice>
            </div>
            <ToursText>Tours in Georgia</ToursText>
          </LeftSideWrapper>
          <RightSideWrapper>
            <StarRateWrapper>
              <StarImg src={StarIconImg} />
              <Rate>{rate}</Rate>
            </StarRateWrapper>
            <LocationDistanceWrapper>
              <LocationIcon src={LocationIconImg} />
              <Distance>{distance}</Distance>
            </LocationDistanceWrapper>
          </RightSideWrapper>
        </Rectangle>
      </OfferBackgroundImg>
    </OfferContainer>
  );
};

export default OfferCard;
