import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloLink, createHttpLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log('TCL: errorLink -> message', message);

      console.log(
        `[GraphQL error]: Message: ${JSON.stringify(
          message
        )}, Location: ${JSON.stringify(locations)}, Path: ${path}`
      );
    });

  if (networkError) {
    console.log('[Network error]:', networkError);
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default client;
