import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
  background-color: transparent;
  ${({ theme }) => theme.laptop`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4.1rem 10rem;
  `};
`;

export const MobileHeaderRectangle = styled.div`
  position: fixed;
  width: 100%;
  height: 5rem;
  z-index: 11;
  background-color: ${({ theme }) => theme.colors.grey};
  ${({ theme }) => theme.laptop`
    display: none;
  `}
`;

export const LogoIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5.8rem 2.5rem;
  ${({ theme }) => theme.laptop`
    padding: 0;
  `}
`;

export const IconWrapper = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const MenuIcon = styled.img`
  ${({ theme }) => theme.laptop`
    display: none;
  `}
`;

export const Nav = styled.nav`
  display: ${props => (props.showMenu ? 'block' : 'none')};
  ${({ theme }) => theme.laptop`
    display: block;
`}
`;

export const NavList = styled.ul`
  ${({ theme }) => theme.laptop`
    display: flex;
    align-items: center;
    font-size: 1.4rem;
  `}
`;

export const NavListItem = styled.li`
  ${({ theme }) => theme.laptop`
    cursor: pointer;
    list-style-type: none;
    color: ${({ theme, globalIcon }) =>
      globalIcon
        ? theme.colors.whiteWithOpacity(1)
        : theme.colors.whiteWithOpacity(0.75)};
    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
    font-family: ${({ theme }) => theme.fonts.primaryRegular};
    }
    border-right: ${({ theme, hasBorder }) =>
      hasBorder
        ? `0.1rem solid ${theme.colors.whiteWithOpacity(0.2)}`
        : 'none'};
    padding: 0 3rem;
    `}
`;
