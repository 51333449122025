import {
  Container,
  Description,
  ContentTitle,
  SectionWrapper,
  Img,
  ContentWrapper,
  Heading,
  SubHeading,
  Paragraph,
  Border,
  SectionWrapperMobile,
} from './aboutUs.style';
import ForHostImg from 'assets/imgs/for-hosts.png';
import ForTouristsImg from 'assets/imgs/for-tourists.png';
import ForLocalImg from 'assets/imgs/for-local-businesses.png';
import WhoAreWeSection from './who-are-we-section/WhoAreWeSection';

const AboutUs = () => {
  return (
    <Container>
      <Description>
        Thank you for showing up at SpiceApp.me The site is still under
        construction, as well as our mobile app, but here are some good news –
        We’ll hit the our first pilot market (Rep of Georgia) in early 2023!
      </Description>
      <ContentTitle>So, what is SpiceApp anyway?</ContentTitle>
      <SectionWrapper>
        <Img src={ForTouristsImg} alt="for tourists" />
        <ContentWrapper>
          <Heading>For Tourists</Heading>
          <SubHeading>
            SpiceApp is the platform to get the most out of their trip.{' '}
          </SubHeading>
          <Paragraph>
            Scan the QR code provided by your host and spice up your stay with
            the best deals & offers from the most visited as well as hidden gems
            of the city. Have fun in the Tinder-style – save the deals you love
            and swite out the others.
          </Paragraph>
        </ContentWrapper>
      </SectionWrapper>
      <SectionWrapper isMiddle>
        <ContentWrapper isMiddle>
          <Heading>For Hosts</Heading>
          <SubHeading>
            SpiceApp is the platform to earn some extra cash.
          </SubHeading>
          <Paragraph>
            Just place the QR code in your hotel rooms / apartments and do what
            you gotta do – recommend some cool places to your guests, only this
            time with deals & offers.
          </Paragraph>
        </ContentWrapper>
        <Img src={ForHostImg} alt="for hosts" isMiddle />
      </SectionWrapper>
      <SectionWrapperMobile>
        <Img src={ForHostImg} alt="for hosts" isMiddle />
        <ContentWrapper isMiddle>
          <Heading>For Hosts</Heading>
          <SubHeading>
            SpiceApp is the platform to earn some extra cash.
          </SubHeading>
          <Paragraph>
            Just place the QR code in your hotel rooms / apartments and do what
            you gotta do – recommend some cool places to your guests, only this
            time with deals & offers.
          </Paragraph>
        </ContentWrapper>
      </SectionWrapperMobile>
      <SectionWrapper>
        <Img src={ForLocalImg} alt="for local" />
        <ContentWrapper>
          <Heading>For Local Businesses</Heading>
          <SubHeading>
            SpiceApp is the platform to push your potentical to the limits.
          </SubHeading>
          <Paragraph>
            Hop on with your best offer and let the hosts do the rest – thanks
            to our QR code, they will bring you the well-desired new clients –
            the tourists. Serve them your way and collect the reviews.
          </Paragraph>
        </ContentWrapper>
      </SectionWrapper>
      <Border />
      <WhoAreWeSection />
    </Container>
  );
};

export default AboutUs;
