import {
  ButtonContainer,
  TransparentButtonWrapper,
  ContentWrapper,
} from './button.style';

const CustomButton = ({
  buttonType,
  children,
  textColor,
  src,
  onClick,
  ...rest
}) => {
  return (
    <ButtonContainer
      buttonType={buttonType}
      textColor={textColor}
      onClick={onClick}
      {...rest}
    >
      <TransparentButtonWrapper>
        {!!src && <img src={src} alt="icon" />}
      </TransparentButtonWrapper>
      <ContentWrapper>{children}</ContentWrapper>
      {!!src && <img src={src} alt="icon" />}
    </ButtonContainer>
  );
};

export default CustomButton;
