import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 4rem 5rem;
  ${({ theme }) => theme.laptop`
     padding: 3rem 0 20rem;
   `}
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  margin: 3rem 0 4rem;
  text-align: center;
  line-height: 3rem;
  min-width: 10rem;
  max-width: 60rem;
  ${({ theme }) => theme.laptop`
      margin-top: ${({ isFirst }) => (isFirst ? '0' : '5rem')};
   `}
`;

export const ContentTitle = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.5rem;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  text-align: center;
  ${({ theme }) => theme.laptop`
    text-align: unset;
  `}
`;

export const SectionWrapper = styled.div`
  display: ${({ isMiddle }) => (isMiddle ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: 6rem;
  align-items: center;
  ${({ theme }) => theme.laptop`
    flex-direction: row;
    align-items: unset;
    display: flex;
    `}
`;

export const Img = styled.img`
  width: 30rem;
  border-radius: 0.5rem;
  ${({ theme }) => theme.laptop`
    width: 40rem;
    margin-left: ${({ isMiddle }) => (isMiddle ? '2rem' : '0')};
`}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  align-items: center;
  ${({ theme }) => theme.laptop`
    margin-left: ${({ isMiddle }) => (isMiddle ? '0' : '4rem')};
    max-width: unset;
    align-items: unset;
   `}
`;

export const Heading = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  margin-top: 2rem;
  ${({ theme }) => theme.laptop`
    margin-top: 0;
   `}
`;

export const SubHeading = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  width: 30rem;
  margin-top: 3rem;
  text-align: center;
  ${({ theme }) => theme.laptop`
     width: 40rem;
     text-align: unset;
  `}
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  text-align: center;
  margin-top: 3rem;
  ${({ theme }) => theme.laptop`
    width: 40rem;
    text-align: unset;
  `}
`;

export const Border = styled.div`
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.colors.grey};
  margin: 7rem 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8rem;
  ${({ theme }) => theme.laptop`
     flex-direction: row;
  `}
`;

export const SectionWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  align-items: center;
  ${({ theme }) => theme.laptop`
    display: none;
   `}
`;
