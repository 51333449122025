import { Link } from 'react-router-dom';

import {
  FooterContainer,
  LeftSection,
  LogoSloganWrapper,
  LogoWrapper,
  SloganText,
  Border,
  MiddleRightSectionWrapper,
  MiddleSectionWrapper,
  Title,
  LinkTextWrapper,
  LinkText,
  LinkListItem,
  RightSection,
  ContactDetailsText,
  ContactNumber,
  IconsWrapper,
  IconWrapper,
} from './footer.style';
import LogoSrc from 'assets/icons/Logo-image.svg';
import FacebookIcon from 'assets/icons/facebook-icon.svg';
import InstagramIcon from 'assets/icons/instagram-icon.svg';
import YoutubeIcon from 'assets/icons/youtube-icon.svg';
import IconButton from 'components/common/icon-button/IconButton';
import SCREEN_NAMES from 'constants/screen-names';

const Footer = () => {
  return (
    <FooterContainer>
      <LeftSection>
        <LogoSloganWrapper>
          <LogoWrapper>
            <Link to="/">
              <img src={LogoSrc} alt="logo" />
            </Link>
          </LogoWrapper>
        </LogoSloganWrapper>
        <SloganText>
          Some SpiceApp slogan here, based on business goals
        </SloganText>
        <Border />
        <LinkTextWrapper>
          <LinkText PrivacyPolicy>Privacy Policy</LinkText>
          <LinkText termsConditions>Terms & Conditions</LinkText>
        </LinkTextWrapper>
        <Border border />
      </LeftSection>
      <MiddleRightSectionWrapper>
        <MiddleSectionWrapper>
          <Title>Quick Links</Title>
          <ul>
            <LinkListItem>
              <Link to="/">Home</Link>
            </LinkListItem>
            <LinkListItem>
              <Link to={SCREEN_NAMES.ABOUT}>About us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/host">I'm a host</Link>
            </LinkListItem>
            <LinkListItem>
              <Link to="/vendor">I'm a vendor</Link>
            </LinkListItem>
          </ul>
        </MiddleSectionWrapper>
        <Border />
        <RightSection>
          <Title>Contact</Title>
          <ContactDetailsText>info@spiceapp.me</ContactDetailsText>
          <ContactNumber>+(995) 599 394 439 </ContactNumber>
          <IconsWrapper>
            <IconWrapper>
              <IconButton icon={FacebookIcon} alt="facebook icon" hasHover />
            </IconWrapper>
            <IconWrapper>
              <IconButton icon={InstagramIcon} alt="instagram icon" hasHover />
            </IconWrapper>
            <IconWrapper>
              <IconButton icon={YoutubeIcon} alt="youtube icon" hasHover />
            </IconWrapper>
          </IconsWrapper>
        </RightSection>
      </MiddleRightSectionWrapper>
      <Border />
      <ContactDetailsText copyRight>
        © 2021 SpiceApp. All rights reserved
      </ContactDetailsText>
    </FooterContainer>
  );
};

export default Footer;
