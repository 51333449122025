import {
  CategoryWrapper,
  CategoryImg,
  CategoryText,
} from 'components/common/category/category.style';

const Category = ({ src, text, hasBorder, handleClick, hasBackground }) => {
  return (
    <CategoryWrapper
      hasBackground={hasBackground}
      hasBorder={hasBorder}
      onClick={handleClick}
    >
      <CategoryImg src={src} />
      <CategoryText>{text}</CategoryText>
    </CategoryWrapper>
  );
};

export default Category;
