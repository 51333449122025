import { gql } from '@apollo/client';

export const createOfferMutation = gql`
  mutation createOffer($record: CreateOfferRecord!) {
    createOffer(record: $record) {
      uid
      description
      name
      mediaUrls
      totalPrice
      discountedPrice
      vendor {
        uid
        name
        categories {
          uid
        }
      }
      status
    }
  }
`;
