import { ButtonContainer } from './iconButton.style';

const IconButton = ({ icon, alt, isPrimary, onClick = () => {} }) => {
  return (
    <ButtonContainer onClick={onClick} isPrimary={isPrimary} type="button">
      <img src={icon} alt={alt} />
    </ButtonContainer>
  );
};

export default IconButton;
