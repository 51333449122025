import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import 'react-phone-number-input/style.css';

import MontserratBold from 'assets/fonts/Montserrat-Bold.ttf';
import MontserratBoldItalic from 'assets/fonts/Montserrat-BoldItalic.ttf';
import MontserratItalic from 'assets/fonts/Montserrat-Italic.ttf';
import MontserratLight from 'assets/fonts/Montserrat-Light.ttf';
import MontserratLightItalic from 'assets/fonts/Montserrat-LightItalic.ttf';
import MontserratMedium from 'assets/fonts/Montserrat-Medium.ttf';
import MontserratMediumItalic from 'assets/fonts/Montserrat-MediumItalic.ttf';
import MontserratRegular from 'assets/fonts/Montserrat-Regular.ttf';
import MontserratSemiBold from 'assets/fonts/Montserrat-SemiBold.ttf';
import MontserratSemiBoldItalic from 'assets/fonts/Montserrat-SemiBoldItalic.ttf';

const GlobalStyles = createGlobalStyle`
  ${normalize}
  a {
    text-decoration: none;
    color: unset;
  }
  h1, h2, h3, h4, h5 {
    font-size: unset;
    margin: 0rem;
    font-weight: normal;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
  }
  html {
    font-size: 62.5%;
  }
  ul {
    list-style-type: none;
  }

  @font-face {
    font-family: ${({ theme }) => theme.fonts.primaryBold};
    src: url(${MontserratBold});
  }
  @font-face {
    font-family: ${({ theme }) => theme.fonts.primaryBoldItalic};;
    src: url(${MontserratBoldItalic});

  }
  @font-face {
    font-family: ${({ theme }) => theme.fonts.primaryItalic};;
    src: url(${MontserratItalic});

  }
  @font-face {
    font-family: ${({ theme }) => theme.fonts.primaryLight};;
    src: url(${MontserratLight});

  }
  @font-face {
    font-family: ${({ theme }) => theme.fonts.primaryLightItalic};;
    src: url(${MontserratLightItalic});
 }
  @font-face {
    font-family: ${({ theme }) => theme.fonts.primaryMedium};;
    src: url(${MontserratMedium});
 }
  @font-face {
    font-family: ${({ theme }) => theme.fonts.primaryMediumItalic};;
    src: url(${MontserratMediumItalic});
 }
  @font-face {
    font-family: ${({ theme }) => theme.fonts.primaryRegular};;
    src: url(${MontserratRegular});
 }
  @font-face {
    font-family: ${({ theme }) => theme.fonts.primarySemiBold};;
    src: url(${MontserratSemiBold});
 }
  @font-face {
    font-family: ${({ theme }) => theme.fonts.primarySemiBoldItalic};;
    src: url(${MontserratSemiBoldItalic});
 }

`;
export default GlobalStyles;
