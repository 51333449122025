import { useEffect, useState, useCallback } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';

import { meQuery } from 'gql';

const useAuth = () => {
  const client = useApolloClient();
  const [user, setUser] = useState(null);
  const [hasToken, setHasToken] = useState(
    () => !!localStorage.getItem('token')
  );

  const { data, loading, refetch, error } = useQuery(meQuery, {
    fetchPolicy: 'no-cache',
    skip: !hasToken,
  });

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data]);

  const setToken = token => {
    localStorage.setItem('token', token);
    setHasToken(true);
  };

  const removeToken = useCallback(() => {
    localStorage.removeItem('token');
    setHasToken(false);
    client.clearStore();
  }, [client]);

  const logout = () => {
    removeToken();
  };

  useEffect(() => {
    if (error && !loading) {
      removeToken();
    }
  }, [error, loading, removeToken]);

  return {
    setToken,
    removeToken,
    me: data && data.me ? data.me : user || undefined,
    meLoading: loading,
    meRefetch: refetch,
    setUser: user => setUser(user),
    hasToken,
    logout,
  };
};

export default useAuth;
