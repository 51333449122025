import {
  Title,
  Wrapper,
  ImgTitleWrapper,
  OwnerImg,
  Description,
} from './whoAreWeSection.style';
import LikoImg from 'assets/imgs/liko.png';
import SabaImg from 'assets/imgs/saba.png';
import SibaImg from 'assets/imgs/siba.png';

const WhoAreWeSection = () => {
  return (
    <>
      <Title isFirst>Who are we?</Title>
      <Wrapper>
        <ImgTitleWrapper>
          <OwnerImg src={LikoImg} alt="owner" isFirst />
          <Title>Lily Gabadadze</Title>
        </ImgTitleWrapper>
        <ImgTitleWrapper>
          <OwnerImg src={SabaImg} alt="owner" />
          <Title>Saba Turdzeladze</Title>
        </ImgTitleWrapper>
        <ImgTitleWrapper>
          <OwnerImg src={SibaImg} alt="owner" />
          <Title>Giorgi Sibashvili</Title>
        </ImgTitleWrapper>
      </Wrapper>
      <Description>
        We are the professionals from Tbilisi, Republic of Georgia. Our team has
        all that it takes to build the successful startup: We’re experienced in
        developing digital products, we have deep knowledge of the hospitality
        market, we are globally-minded people located in the “7th in Ease of
        Doing Business” environment and fast-growing economy and last but not
        the least, we are confident and we trust each other. So, brace
        yourselves, SpiceApp is coming!
      </Description>
    </>
  );
};

export default WhoAreWeSection;
