import styled from 'styled-components';

export const DownloadWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding-left: 2.6rem;
  margin: 7rem 0;
  ${({ theme }) => theme.laptop`
      align-items: center;
      justify-content: center;
      padding-left: 24.7rem;
    `}
`;

export const DownloadHeading = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 3.6rem;
  width: 26.1rem;
  ${({ theme }) => theme.laptop`
    width: 30.3rem;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 4rem;
  ${({ theme }) => theme.laptop`
    padding-top: 3rem;
  `}
`;

export const Button = styled.button`
  width: 15rem;
  height: 4.8rem;
  border-radius: 1.2rem;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 2.3rem;
  cursor: pointer;
  :first-child {
    margin-left: 0;
  }
`;

export const ImgWrapper = styled.div`
  padding-top: 4rem;
  display: flex;
  ${({ theme }) => theme.laptop`
    display: none;
  `}
`;

export const DownloadImg = styled.img`
  width: 23.4rem;
  :nth-child(2) {
    margin-left: 1.6rem;
  }
  ${({ theme }) => theme.laptop`
     margin: 9rem 0 16.8rem;
     margin-left: 4.4rem;
     :first-child {
       margin-left: 0;
     }
     :nth-child(2) {
      margin-top: 16.8rem;
      margin-left: 4.4rem;
    } 
    `}
`;

export const ResponsiveImgWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.laptop`
    display: flex;
    margin-left: 15rem;
    `}
`;
