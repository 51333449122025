import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100%;
  padding: 7rem 0;
  background-color: ${({ theme }) => theme.colors.lightBlack};
  ${({ theme }) => theme.laptop`
    display: flex;
    padding: 9rem 10rem;
    justify-content: space-between;
    position: relative;
  `}
`;

export const LeftSection = styled.div`
  ${({ theme }) => theme.laptop`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const LogoSloganWrapper = styled.div`
  padding-left: 2.6rem;
  ${({ theme }) => theme.laptop`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0;
  `}
`;

export const LogoWrapper = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const SloganText = styled.p`
  padding-top: 2.9rem;
  padding-left: 2.6rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  width: 32.3rem;
  ${({ theme }) => theme.laptop`
    font-size: 1.6rem;
    padding-left: 0;
`}
`;

export const Border = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.lightGrey};
  padding: 4rem 0 0;
  ${({ theme }) => theme.laptop`
    display: none;
`}
`;

export const LinkTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-left: 2.6rem;
  ${({ theme }) => theme.laptop`
    padding-top: 6.7rem;
    padding-left: 0;
  `}
}
`;

export const LinkText = styled.a`
  color: ${({ theme }) => theme.colors.white};
  padding-top: ${props => (props.termsConditions ? '2rem' : '0')};
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: 1.4rem;
  cursor: pointer;
  ${({ theme }) => theme.laptop`
    text-decoration: underline;
    font-size: 1.6rem;
  `}
`;

export const MiddleRightSectionWrapper = styled.div`
  ${({ theme }) => theme.laptop`
      display: flex;
      `}
`;

export const MiddleSectionWrapper = styled.div`
  padding-left: 2.6rem;
  ${({ theme }) => theme.laptop`
    padding-left: 0;
    margin-right: 18.8rem;
  `}
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 1.6rem;
  padding-top: 4rem;
  ${({ theme }) => theme.laptop`
    font-size: 1.8rem;
    padding-top: unset;
  `}
`;

export const LinkListItem = styled.li`
  padding-top: 2rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.75;
  font-size: 1.6rem;
`;

export const RightSection = styled.div`
  padding-left: 2.6rem;
  ${({ theme }) => theme.laptop`
    padding-left: 0;
  `}
`;

export const ContactDetailsText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.75;
  font-size: 1.6rem;
  padding-top: ${({ copyRight }) => (copyRight ? '5rem' : '2rem')};
  padding-left: ${({ copyRight }) => (copyRight ? '2.6rem' : '0')};
  ${({ theme }) => theme.laptop`
    position: ${({ copyRight }) => (copyRight ? 'absolute' : 'none')};
    right: 10rem;
    top: 28.4rem;
   `}
`;

export const ContactNumber = styled.button`
  padding: 0;
  border: 0;
  background: none;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.75;
  font-size: 1.6rem;
  padding-top: 2rem;
`;

export const IconsWrapper = styled.div`
  display: flex;
  padding-top: 2rem;
`;

export const Circle = styled.div`
  background-color: ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${props => (props.firstIcon ? '0rem' : '2rem')};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  ${({ theme }) => theme.laptop`
    margin-top: 2rem;
  `}
`;

export const IconWrapper = styled.div`
  margin-left: 2rem;
  &:first-child {
    margin-left: 0;
  }
`;
