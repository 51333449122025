import {
  Container,
  BackgroundImg,
  ContentWrapper,
  HostHeading,
  HostText,
  ButtonWrapper,
} from './becomeSection.style';
import CustomButton from 'components/common/button/Button';

const BecomeSection = ({ heading, text, bg, bgLaptop, onClick }) => {
  return (
    <Container>
      <BackgroundImg imgLaptop={bgLaptop} imgMobile={bg} alt="host background">
        <ContentWrapper>
          <HostHeading>{heading}</HostHeading>
          <HostText>{text}</HostText>
          <ButtonWrapper>
            <CustomButton
              buttonType="primary"
              textColor="black"
              onClick={onClick}
            >
              Learn More
            </CustomButton>
          </ButtonWrapper>
        </ContentWrapper>
      </BackgroundImg>
    </Container>
  );
};

export default BecomeSection;
