import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 14.9rem 0;
  width: 100%;
  ${({ theme }) => theme.laptop`
    padding: 9rem 0;
 `}
`;

export const BackgroundImg = styled.div`
  background: linear-gradient(
      133deg,
      ${({ theme }) => theme.colors.grey},
      rgba(41, 45, 50, 0)
    ),
    ${props => `url(${props.imgMobile})`};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 70rem;
  border-radius: 2rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 10rem;
  ${({ theme }) => theme.laptop`
    background: linear-gradient(133deg, ${({ theme }) =>
      theme.colors.grey}, rgba(41, 45, 50, 0)), ${props =>
    `url(${props.imgLaptop})`};
    align-items: center;
    justify-content: unset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.4rem 2.4rem;
  ${({ theme }) => theme.laptop`
     margin-left: 9rem;
     padding: 0;
     align-items: unset;
     `}
`;

export const HostHeading = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: 2.4rem;
  ${({ theme }) => theme.laptop`
    font-size: 4.7rem;
  `}
`;

export const HostText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: 1.4rem;
  width: 27.5rem;
  padding-top: 1rem;
  ${({ theme }) => theme.laptop`
    font-size: 1.6rem;
    padding-top: 2rem;
   `}
`;

export const ButtonWrapper = styled.div`
  width: 25.5rem;
  height: 4.8rem;
  margin-top: 2rem;
  ${({ theme }) => theme.laptop`
    width: 19rem;
    margin-top: 3rem;
  `}
`;
