import {
  DownloadWrapper,
  DownloadHeading,
  ResponsiveImgWrapper,
  ImgWrapper,
  DownloadImg,
  ButtonWrapper,
  Button,
} from './downloadSection.style';
import DownloadImage from 'assets/imgs/download-img.svg';
import DownloadImgLaptop from 'assets/imgs/download-laptop.svg';
import DownloadImgLaptop2 from 'assets/imgs/customize-laptop.svg';
import AppleIcon from 'assets/icons/apple-icon.svg';
import GooglePlayIcon from 'assets/icons/google-play.svg';

const DownloadSection = () => {
  return (
    <DownloadWrapper>
      <div>
        <DownloadHeading>Download Our SpiceApp</DownloadHeading>
        <ImgWrapper>
          <DownloadImg src={DownloadImage} />
          <DownloadImg isMobile src={DownloadImgLaptop2} />
        </ImgWrapper>
        <ButtonWrapper>
          <Button>
            <img src={AppleIcon} alt="apple icon" />
          </Button>
          <Button>
            <img src={GooglePlayIcon} alt="google play icon" />
          </Button>
        </ButtonWrapper>
      </div>
      <ResponsiveImgWrapper>
        <DownloadImg src={DownloadImgLaptop} />
        <DownloadImg src={DownloadImgLaptop2} />
        <DownloadImg src={DownloadImgLaptop2} />
      </ResponsiveImgWrapper>
    </DownloadWrapper>
  );
};

export default DownloadSection;
