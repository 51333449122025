import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8rem;
  ${({ theme }) => theme.laptop`
     flex-direction: row;
  `}
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ isFirst }) => (isFirst ? '2.5rem' : '1.8rem')};
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  margin: ${({ isFirst }) => (isFirst ? '0' : '3rem')};
`;

export const OwnerImg = styled.img`
  width: 20rem;
  ${({ theme }) => theme.laptop`
     margin-left: ${({ isFirst }) => (isFirst ? '0' : '4rem')};
  `}
`;

export const ImgTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  margin-top: 4rem;
  text-align: center;
  max-width: 60rem;
  min-width: 30rem;
  ${({ theme }) => theme.laptop`
    margin-top: 6rem;
  `}
`;
