import { gql } from '@apollo/client';

export const registerVendorMutation = gql`
  mutation register($record: CreateVendorOrHostRecord!) {
    registerVendor(record: $record) {
      vendor {
        uid
      }
      accessToken
    }
  }
`;
